<template>
  <qmp-layout bg-color="#fff">
    <!--头部-->
    <div slot="header" class="flex-center pv-3x bd-b">
      <div class="flex-start tabs">
        <p
          class="tabItem"
          :class="{ tabActive: tabActive === '1' }"
          @click="tabChange('1')"
        >
          兑换币套餐
        </p>
        <p
          class="tabItem"
          :class="{ tabActive: tabActive === '2' }"
          @click="tabChange('2')"
        >
          兑换券
        </p>
      </div>
    </div>
    <!-- 内容-->
    <div class="rechargeMain">
      <!--兑换币套餐-->
      <a-row v-show="tabActive === '1'" :gutter="[0, 30]">
        <a-col :span="24" v-for="(item, index) in coinMoneyList" :key="index">
          <div
            @click="selectProduct(item)"
            class="shadow coin_money_card"
            :class="currOrderId === item.productId ? 'coin_bg' : ''"
          >
            <div class="flex-between">
              <span class="font-xxl text-1 dark">{{ item.name }}</span>
              <span class="flex-shrink ml-2x price">{{
                item.sellingPrice | money
              }}</span>
            </div>
            <div class="gray mt-1x ">
              <a-row class="flex-start">
                <a-col class="flex-shrink" :span="12">
                  充值总余额：{{ item.memberBalance | money("") }}
                </a-col>
                <a-col class="flex-shrink" :span="12">
                  赠送积分：{{ item.giftPoints }}
                </a-col>
              </a-row>
              <a-row class="mt-12 flex-start">
                <a-col :span="12"> 赠送成长值：{{ item.giftGrowth }} </a-col>
                <a-col :span="12">
                  <span v-if="item.validStartTime && item.validEndTime"
                    >余额有效期：{{ item.validStartTime | date }}~{{
                      item.validEndTime | date
                    }}</span
                  >
                  <span v-else>有效期： -</span>
                </a-col>
              </a-row>
              <p class="mt-12 text-2">
                赠送优惠券： {{ item.giftCoupons || "无" }}
              </p>
            </div>
            <!--表格table-->
            <h4 class="font-xl text-1 dark pv-2x">套餐明细</h4>
            <div class="mt-x table_bd">
              <a-row class="pv-14 gray-light text">
                <a-col :span="9" class="pl-3x">
                  兑换币名称
                </a-col>
                <a-col :span="4" class="pl-3x">
                  金额
                </a-col>
                <a-col :span="11" class="pl-3x">
                  可用产品
                </a-col>
              </a-row>
              <template v-for="(temp, idx) in 2">
                <a-row class="pv-14 trRow" :key="idx">
                  <a-col :span="9" class="text-1 pl-3x">
                    {{
                      item.exchangeCoinList[idx] &&
                        item.exchangeCoinList[idx].name
                    }}
                  </a-col>
                  <a-col :span="4" class="pl-3x">
                    {{
                      item.exchangeCoinList[idx] &&
                        item.exchangeCoinList[idx].assetNum | money("")
                    }}
                  </a-col>
                  <a-col :span="11" class="text-1 pl-3x">
                    <span>{{
                      item.exchangeCoinList[idx] &&
                        item.exchangeCoinList[idx].applicableProducts
                    }}</span>
                  </a-col>
                </a-row>
              </template>
              <a-row class="pv-14 trRow">
                <a-col
                  v-if="
                    item.exchangeCoinList && item.exchangeCoinList.length > 2
                  "
                  :span="24"
                >
                  <p class="blue flex-center" @click="moreClick">查看更多 ></p>
                </a-col>
                <a-col v-else></a-col>
              </a-row>
            </div>
            <div class="mt-2x pt-x flex-end">
              <span
                v-show="currOrderId === item.productId"
                class="icon iconfont icon-duia"
              ></span>
              <span
                v-show="currOrderId !== item.productId"
                class="icon iconfont icon-duia1"
              ></span>
            </div>
          </div>
        </a-col>
      </a-row>
      <!--兑换券  -->
      <a-row v-show="tabActive === '2'" :gutter="[0, 30]">
        <a-col :span="24" v-for="(item, idx) in coinList" :key="idx">
          <div
            @click="selectProduct(item)"
            class="shadow coin_money_card"
            :class="currOrderId === item.productId ? 'coin_bg' : ''"
          >
            <div class="flex-between">
              <span class="font-xxl text-1 dark">{{ item.name }}</span>
              <span class="flex-shrink ml-2x price">{{
                item.sellingPrice | money
              }}</span>
            </div>
            <div class="gray mt-1x">
              <span v-if="item.validStartTime && item.validEndTime">
                有效期：
                {{ item.validStartTime }} ~ {{ item.validEndTime }}
              </span>
              <span v-else>有效期： -</span>
              <div class="mt-12 flex-between">
                <p class="flex-x text-2">兑换规则：{{ item.convertRules }}</p>
                <p class="blue flex-shrink pl-2x" @click="moreClick">更多</p>
              </div>
            </div>
            <div class="mt-2x flex-end">
              <span
                v-show="currOrderId === item.productId"
                class="icon iconfont icon-duia"
              ></span>
              <span
                v-show="currOrderId !== item.productId"
                class="icon iconfont icon-duia1"
              ></span>
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
    <!--底部-->
    <div slot="footer" class="flex-between ph-4x pv-3x">
      <p>
        合计：
        <span class="price">{{ productParams.sellingPrice | money }}</span>
      </p>
      <div class="flex-end">
        <a-form-model
          class="footer-form"
          ref="form"
          :model="form"
          :label-col="{ span: 8 }"
          :wrapper-col="{ span: 16 }"
        >
          <!-- 审批状态：1免审核，2审核中，3拒绝，4通过 -->
          <a-form-model-item
            prop="rechargeValue"
            label="充值对象"
            :rules="[
              {
                required: true,
                message: '请输入充值对象',
                trigger: 'change'
              },
              {
                validator: memberId ? checkPhone : null
              }
            ]"
          >
            <a-input
              class="input-p"
              v-model="form.rechargeValue"
              placeholder="请输入充值对象"
              allow-clear
              type="tel"
            >
            </a-input>
          </a-form-model-item>
        </a-form-model>
        <qmp-button
          class="ml-3x"
          :loading="qmpLoading"
          type="primary"
          @click="createOrder"
          >确定</qmp-button
        >
      </div>
    </div>
    <!--  弹框  兑换币查看更多-->
    <basics width="80%" v-model="showDialog">
      <div slot="header" class="flex-center pb-2x bd-b font-xl">
        提示
      </div>
      <div
        slot="content"
        class="font-l p-2x"
        style="max-height:1000px;overflow-y: auto;"
      >
        <!--兑换币套餐-->
        <div v-if="tabActive === '1'" class="mt-x table_bd">
          <a-row class="pv-14 gray-light text">
            <a-col :span="9" class="pl-3x">
              兑换币名称
            </a-col>
            <a-col :span="4" class="pl-3x">
              金额
            </a-col>
            <a-col :span="11" class="pl-3x">
              可用产品
            </a-col>
          </a-row>
          <a-row
            v-for="(item, idx) in productParams.exchangeCoinList"
            class="pv-14 trRow"
            :key="idx"
          >
            <a-col :span="9" class="pl-3x">{{ item.name }}</a-col>
            <a-col :span="4" class="pl-3x">{{
              item.assetNum | money("")
            }}</a-col>
            <a-col :span="11" class="pl-3x">{{
              item.applicableProducts
            }}</a-col>
          </a-row>
        </div>
        <!--兑换券弹框-->
        <div v-else class="mt-x">
          兑换规则：{{ productParams.convertRules }}
        </div>
      </div>
    </basics>
  </qmp-layout>
</template>

<script>
import Basics from "@/components/dialog/basics";
import { productFrontMembervoucher, productFrontpackage } from "@/api/search"; // MS-搜索中心接口
import { orderOneMemberInfo } from "@/api/member"; // MS-会员中心接口
import { memberRecharge } from "@/api/cart"; // MS-购物车中心接口
import { createQuickOrder } from "@/api/order";
import { checkPhone } from "../../../utils/global";
export default {
  name: "RechargeIndex",
  components: { Basics },
  data() {
    return {
      checkPhone,
      tabActive: "1",
      currOrderId: 0, // 当前充值套餐id
      productParams: {}, // 变更产品参数对象
      coinMoneyList: [], // 兑换币列表
      coinList: [], // 兑换券列表
      showDialog: false,
      qmpLoading: false,
      // mobile: "", // 手机号
      memberId: "", // 会员id
      form: {
        rechargeValue: "" // 充值对象
      }
    };
  },
  created() {
    this.form.rechargeValue = this.$route.query.mobile || "";
    this.memberId = this.$route.query.memberId;
    this.productFrontpackageLoad();
  },
  methods: {
    // 加载更多
    moreClick() {
      this.showDialog = true;
    },
    // tab切换
    tabChange(key) {
      this.currOrderId = 0; // 变更充值套餐id
      this.productParams = {}; // 变更产品参数对象
      this.tabActive = key;
      if (key === "1") {
        this.productFrontpackageLoad();
      } else this.productFrontMembervoucherLoad(); // 查询资产套餐列表
    },
    // 加载兑换币套餐
    productFrontpackageLoad() {
      productFrontpackage().then(res => {
        this.coinMoneyList = res || [];
      });
    },
    // 加载兑换券
    productFrontMembervoucherLoad() {
      productFrontMembervoucher({
        page: 1,
        size: 99
      }).then(res => {
        this.coinList = res.records;
      });
    },
    // 选择充值的套餐
    selectProduct(item) {
      this.currOrderId = item.productId; // 变更充值套餐id
      this.productParams = item; // 变更产品参数对象
    },
    // 会员充值
    createOrder() {
      if (!this.productParams.productId) {
        this.$message.error(
          this.tabActive === "1" ? "请先选择兑换币套餐" : "请先选择兑换券"
        );
        return;
      } else if (!this.form.rechargeValue) {
        this.$message.error("充值对象必填!");
      }
      this.$refs.form.validate(valid => {
        if (valid) {
          this.checkPhoneFn(this.form.rechargeValue);
        }
      });
    },
    checkPhoneFn(val) {
      if (val) {
        // 根据会员号查询会员详情
        orderOneMemberInfo({
          evidence: val
        }).then(res => {
          this.memberRechargeFn(res);
        });
      }
    },
    memberRechargeFn(memberObj) {
      this.qmpLoading = true;
      // // 新会员充值
      let item = this.productParams;
      let obj = {
        rechargeTo: this.form.rechargeValue,
        giftMemberPhones: [
          {
            productId: item.productId,
            validType: 1,
            validStartTime: item.validStartTime || "", // 有效期开始时间
            validEndTime: item.validEndTime || "" // 有效期结束时间
          }
        ]
      };
      if (this.memberId) {
        // 存在会员id
        obj.purchaserMemberId = this.memberId;
        obj.whetherTemporaryMember = 0; // 自助机能登录进来，就是永久会员，临时会员暂时无会员详情界面作入口
      } else {
        // 未登录，通过充值对象查找出来的会员id
        obj.purchaserMemberId = memberObj.id;
        obj.whetherTemporaryMember = memberObj.whetherTemporaryMember;
      }
      memberRecharge(obj)
        .then(res => {
          createQuickOrder({ shoppingCartId: res.id })
            .then(res => {
              if (res.settlementPrice === 0) {
                this.success();
              } else {
                // 需要打印，则传业态format
                // format 业态 6票务，13 组合。 orderId 订单id
                this.$router.push({
                  path: "/payment/pay",
                  query: { orderId: res.orderId }
                });
              }
            })
            .finally(() => {
              this.qmpLoading = false;
            });
        })
        .catch(() => {
          this.qmpLoading = false;
        });
    },
    // 支付成功
    success() {
      this.$message.success("支付成功");
      // 调打印和语音播报
      this.currOrderId = 0; // 变更充值套餐id
      this.productParams = {}; // 变更产品参数对象
    }
  }
};
</script>

<style lang="less" scoped>
/*tab头部区域*/
.tabs {
  border-radius: 10px;
  color: #9e9e9e;
  border: 1px solid #999999;
  font-size: 22px;
  height: 60px;
  .tabItem {
    width: 200px;
    height: 60px;
    line-height: 60px;
    border-radius: 10px;
    text-align: center;
  }
  .tabActive {
    color: #ffffff;
    background: #19c748;
  }
  &.bd-left-no {
    border-left: none;
  }
  &.bd-right-no {
    border-right: none;
  }
}
/*内容列表区域*/
.rechargeMain {
  padding: 23px 30px;
  background: #ffffff;
  .gray {
    color: #767676;
  }
  .mt-12 {
    margin-top: 12px;
  }
  .coin_money_card {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    border-radius: 8px;
    padding: 21px 35px 24px 35px;
    .checked {
      position: absolute;
      right: -5px;
      bottom: -5px;
    }
    &.coin_bg {
      box-shadow: 0 0 9px 0 rgba(65, 130, 252, 0.5);
      border: 2px solid #19c748;
    }
    .icon-duia {
      color: #19c748;
      font-size: 36px;
    }
    .icon-duia1 {
      color: #999999;
      font-size: 36px;
    }
  }
}
/*兑换币，兑换券，弹框公用区域*/
.input-p {
  /deep/.ant-input {
    height: 52px;
    font-size: 18px;
  }
  /deep/.ant-input-clear-icon {
    font-size: 20px;
  }
}
.price {
  color: #ff2121;
  font-size: 32px;
}
.table_bd {
  overflow: hidden;
  border: 1px solid #e8e8e8;
  font-size: 16px;
  .trRow {
    color: #767676;
    min-height: 50px;
  }
  > .trRow:not(:last-child) {
    border-bottom: 1px solid #e8e8e8;
  }
  .gray-light {
    color: #000000;
    background: #f6f6f6;
  }
  .pv-14 {
    padding: 14px 0;
  }
}
.footer-form {
  position: relative;
  top: 10px;
}
</style>
